// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('frame')
require('video_player')
require('interview_player')
//require('full_film_player')
require('video_controls')

$(document).on('turbolinks:load', function(){
  // Scroll-to-top button
  $('#back-to-flop').on('click', function(){
    $('html, body').animate({ scrollTop: '0' }, 1000);
  });

  // Scroll one page down
  $('#continue').on('click', function(){
    $('html, body').animate({ scrollTop: $('.title-bar').offset().top }, 1000);
  });

  // Close intro button.
  $('#close-intro').on('click', function(){
    $('#intro-w').remove();
  });
});
$(document).on('turbolinks:load', function(){

  var video_elem     = $('.interview-video-w video');
  var play_btn       = $('.interview-video-w .video-controls-play');
  var fs_btn         = $('.interview-video-w .video-controls-fullscreen-request');
  var current_video;

  // Starts the current video
  function startVideo(video){
    // Stop video unless it is the same as the currently played video
    if(!video.is(current_video)){
      $('.interview-video-w').each(function(){
        $(this).addClass('covered');
        pauseVideo($(this));
      });
      video.parent().removeClass('covered');
    }
    current_video = video;
  }

  video_elem.on('click', function(){startVideo($(this))});
  play_btn.on('click', function(){startVideo($(this).parent().children('video'))});
  fs_btn.on('click', function(){$(this).parent().parent().removeClass('covered')})
});
$(document).on('turbolinks:load', function(){
  var wrapper        = $('#video-player');
  var video          = $('#video-player-video')[0];
  var video_elem     = $('#video-player-video');
  var video_elem_w   = $('#video-player-video-w')[0];
  var video_src      = $('#video-player-src');
  var play_btn       = $('#video-player-play');
  var play_next      = $('#video-player-next');
  var play_last      = $('#video-player-last');
  var fullscreen_btn = $('#video-player-fullscreen');
  var exit_fs_btn    = $('#video-player-fullscreen-close');
  var progress_bar   = $('#video-player-progress')[0];
  var progress_bar_w = $('#video-player-progress-w');

  var start_videos    = $('#video-clicked-preview .circle');
  var start_video_btn = $('#video-clicked-play');
  var data            = $('#video-player-data').data('sources');
  var video_title     = $('#video-player-title');
  var video_desc      = $('#video-player-description');
  var current_video   = 0;

  // REGULAR PART

  window.playKeywordVideo = function(){
    video.play();
    play_btn.html('');
    update_bar = setInterval(updateProgress, 500);
  }

  window.pauseKeywordVideo = function(){
    video.pause();
    play_btn.html('');
    if (typeof update_bar !== 'undefined')
      window.clearInterval(update_bar);
  }

  // Start an stop the video und update progress bar
  function playPause() { 
    if (video.paused){
      playKeywordVideo();
    }
    else {
      pauseKeywordVideo();
    }
  }

  // Open fullscreen video mode
  function openFullscreen() {
    if (video_elem_w.requestFullscreen) {
      video_elem_w.requestFullscreen();
    } else if (video_elem_w.mozRequestFullScreen) { /* Firefox */
      video_elem_w.mozRequestFullScreen();
    } else if (video_elem_w.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      video_elem_w.webkitRequestFullscreen();
    } else if (video_elem_w.msRequestFullscreen) { /* IE/Edge */
      video_elem_w.msRequestFullscreen();
    }
  }

  // Exit fullscreen video mode
  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozECancelullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  // Update progress bar
  function updateProgress() {
    if (!video.ended) {
      var size = parseInt(100 * video.currentTime / video.duration);
      progress_bar.style.width = size + '%';
    } else {
      progress_bar.style.width = '0%';
      window.clearInterval(update_bar);
    }
  }

  // Update video when clicking on bar
  function updateVideoPosition(e){
    var bar_width = progress_bar_w.width();
    var relative_pos = (e.pageX - progress_bar_w.offset().left) / bar_width;
    var newtime = relative_pos * video.duration;
    video.currentTime = newtime;
    progress_bar.style.width = (relative_pos * 100) + '%';
  }

  play_btn.on('click', playPause);
  video_elem.on('click', playPause);
  fullscreen_btn.on('click', openFullscreen);
  exit_fs_btn.on('click', exitFullscreen);
  progress_bar_w.on('click', function(e){updateVideoPosition(e)});


  // SPECIFIC PART

  // Get data from array and update the video player
  function updateData(){
    video_desc.html(decodeURIComponent(data[current_video]['description']).replace( /\+/g, ' ' ));
    video.src = data[current_video]['src'];
  }

  // Update the data array
  function refreshData(){
    current_video = 0;
    data = $('#video-player-data').data('sources');
    updateData();
  }

  // Start playing videos
  function startVideos(){
    wrapper.removeClass('hidden');
    start_video_btn.addClass('hidden');
    refreshData();
    updateSelection();

    playPause();
  };

  function updateSelection(){
    var current = $('#keyword_' + data[current_video]['id']);
    $('.active').each(function(){$(this).removeClass('active');});
    current.addClass('active');
    loopOver(current, 'authors', function(i){
      $('#author_' + i).addClass('active');
    });
    loopOver(current, 'categories', function(i){
      $('#category_' + i).addClass('active');
    });
  }

  // Change the video to a position relative to the current
  function changeVideo(i){
    current_video = (current_video + i) % data.length;
    updateData();
    updateSelection();
    video.play();
  }

  // Get next video
  function getNextVideo(){
    changeVideo(1);
  };

  // Get last video
  function getLastVideo(){
    changeVideo(data.length - 1);
  };

  start_videos.on('click', startVideos)
  video_elem.on('ended', getNextVideo);
  play_next.on('click', getNextVideo);
  play_last.on('click', getLastVideo);
});
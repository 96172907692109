$(document).on('turbolinks:load', function(){

  window.loopOver = function(elem, model, fun){
    var models = elem.data(model);
    for(let i=0; i < models.length; i++){
      fun(models[i]);
    }
  };

  // Add 'hovered' class to target elements
  function hoverIn(elem, attrs){
    elem.addClass('hovered');
    $.each(attrs, function(i, attr){
        loopOver(elem, attr[0], function(i){
          $('#' + attr[1] + '_' + i).addClass('hovered');
        });
      });
    $('#count-video').html(elem.data('count'));
    $('#video-word').html(elem.data('count') == 1 ? "Video" : "Videos");
  }
  // Remove 'hovered' class from target elements
  function hoverOut(elem, attrs){
    elem.removeClass('hovered');
    $.each(attrs, function(i, attr){
        loopOver(elem, attr[0], function(i){
          $('#' + attr[1] + '_' + i).removeClass('hovered');
        });
      });
  }

  // Remove 'clicked' class first and then add to target elements
  function clickElem(elem, attrs){
    pauseKeywordVideo();
    $('.page .elem, .page .col-elem').each(function(){
      $(this).removeClass('active');
      $(this).removeClass('clicked');
      $(this).removeClass('selected');
    });
    elem.addClass('selected');
    $.each(attrs, function(i, attr){
        loopOver(elem, attr[0], function(i){
          $('#' + attr[1] + '_' + i).addClass('clicked');
          $('#' + attr[1] + '_' + i).addClass('active');
        });
      });

    // Change center content
    $('#video-hovered-preview').addClass('hidden');
    $('#video-player').addClass('hidden');
    $('#video-clicked-preview').removeClass('hidden');
    $('#count-clicked-video').html(elem.data('count'));
    $('#video-clicked-word').html(elem.data('count') == 1 ? "Video" : "Videos");

    // Update data
    $('#video-player-data').data('sources', elem.data('sources'));
  }

  // Event listener for hover and click effects
  var keyword_attrs = [['authors', 'author'], ['categories', 'category']]
  var category_attrs = [['authors', 'author'], ['keywords', 'keyword']]
  var author_attrs = [['categories', 'category'], ['keywords', 'keyword']]
  $('.keyword').hover(
    function(){hoverIn($(this), keyword_attrs)},
    function(){hoverOut($(this), keyword_attrs)}
    )
  $('.category').hover(
    function(){hoverIn($(this), category_attrs)},
    function(){hoverOut($(this), category_attrs)}
    )
  $('.author').hover(
    function(){hoverIn($(this), author_attrs)},
    function(){hoverOut($(this), author_attrs)}
    )
  $('.keyword').on('click', function(){clickElem($(this), keyword_attrs)});
  $('.category').on('click', function(){clickElem($(this), category_attrs)});
  $('.author').on('click', function(){clickElem($(this), author_attrs)});

});
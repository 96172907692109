$(document).on('turbolinks:load', function(){

  function initializePlayer(video_controls_w){
    var video             = video_controls_w.children('video')[0];
    var video_elem        = video_controls_w.children('video');
    var play_btn          = video_controls_w.children('.video-controls-play');
    var fullscreen_btn    = video_controls_w.find('.video-controls-fullscreen-request');
    var exit_fs_btn       = video_controls_w.children('.video-controls-fullscreen-close');
    var progress_bar_w    = video_controls_w.find('.video-controls-screen-progress-w');
    var progress_bar      = video_controls_w.find('.video-controls-screen-progress')[0];
    var progress_bar_fs_w = video_controls_w.find('.video-controls-fullscreen-progress-w');
    var progress_bar_fs   = video_controls_w.find('.video-controls-fullscreen-progress')[0];

    play_btn.on('click', function(){playPause(video, play_btn, progress_bar, progress_bar_fs)});
    video_elem.on('click', function(){playPause(video, play_btn, progress_bar, progress_bar_fs)});
    fullscreen_btn.on('click', function(){toggleFullscreen(video_controls_w)});
    exit_fs_btn.on('click', function(){toggleFullscreen(video_controls_w)});
    video_elem.on('ended', function(){resetVideo(play_btn)});
    video_controls_w.mousemove(function(){showControls(play_btn, fullscreen_btn, progress_bar_w, progress_bar_fs_w, exit_fs_btn)});
    progress_bar_w.on('click', function(e){updateVideoPosition(e, video, progress_bar, progress_bar_w)});
    progress_bar_fs_w.on('click', function(e){updateVideoPosition(e, video, progress_bar_fs, progress_bar_fs_w)});
  }

  // Pause video
  function playVideo(video, play_btn, progress_bar, progress_bar_fs){
    video.play();
    play_btn.html('');
    update_bar = setInterval(function(){
      updateProgress(video, progress_bar, progress_bar_fs);
    }, 500);
  }
  window.playVideo = function(video_w){
    playVideo(video_w.children('video')[0],
      video_w.children('.video-controls-play'),
      video_w.find('.video-controls-screen-progress')[0],
      video_w.find('.video-controls-fullscreen-progress')[0]
      )
  }

  // Play video
  function pauseVideo(video, play_btn, progress_bar, progress_bar_fs){
    video.pause();
    play_btn.html('');
    if (typeof update_bar !== 'undefined')
      window.clearInterval(update_bar);
  }
  window.pauseVideo = function(video_w){
    pauseVideo(video_w.children('video')[0],
      video_w.children('.video-controls-play'),
      video_w.find('.video-controls-screen-progress')[0],
      video_w.find('.video-controls-fullscreen-progress')[0]
      )
  }

  // Start an stop the video und update progress bar
  function playPause(video, play_btn, progress_bar, progress_bar_fs) { 
    if (video.paused){
      playVideo(video, play_btn, progress_bar, progress_bar_fs);
    }
    else {
      pauseVideo(video, play_btn, progress_bar, progress_bar_fs);
    }
  }

  // Switch fullscreen mode
  function toggleFullscreen(video_controls_w) {
    // Exit fullscreen
    if(document.fullscreenElement || document.webkitFullscreenElement ||
        document.mozFullScreenElement){
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozECancelullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    } 
    // Open fullscreen video mode
    else {
      if (video_controls_w[0].requestFullscreen) {
        video_controls_w[0].requestFullscreen();
      } else if (video_controls_w[0].mozRequestFullScreen) { /* Firefox */
        video_controls_w[0].mozRequestFullScreen();
      } else if (video_controls_w[0].webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        video_controls_w[0].webkitRequestFullscreen();
      } else if (video_controls_w[0].msRequestFullscreen) { /* IE/Edge */
        video_controls_w[0].msRequestFullscreen();
      }
    }
  }

  // Update progress bar
  function updateProgress(video, progress_bar, progress_bar_fs) {
    if (!video.ended) {
      var size = parseInt(100 * video.currentTime / video.duration);
      progress_bar.style.width = size + '%';
      progress_bar_fs.style.width = size + '%';
    } else {
      progress_bar.style.width = '0%';
      progress_bar_fs.style.width = '0%';
      window.clearInterval(update_bar);
    }
  }

  // Update video when clicking on bar
  function updateVideoPosition(e, video, progress_bar, progress_bar_w){
    var bar_width = progress_bar_w.width();
    var relative_pos = (e.pageX - progress_bar_w.offset().left) / bar_width;
    var newtime = relative_pos * video.duration;
    video.currentTime = newtime;
    progress_bar.style.width = (relative_pos * 100) + '%';
  }

  // Show the video controls for a certain time
  function showControls(play_btn, fullscreen_btn, progress_bar_w, progress_bar_fs_w, exit_fs_btn){
    if (typeof controls_timeout !== 'undefined'){
      window.clearTimeout(controls_timeout);
    }
    play_btn.removeClass('passive');
    fullscreen_btn.removeClass('passive');
    exit_fs_btn.removeClass('passive');
    progress_bar_w.removeClass('passive');
    progress_bar_fs_w.removeClass('passive');

    controls_timeout = window.setTimeout(function(){
      play_btn.addClass('passive');
      fullscreen_btn.addClass('passive');
      exit_fs_btn.addClass('passive');
      progress_bar_w.addClass('passive');
      progress_bar_fs_w.addClass('passive');
    }, 2000);
  }

  // Resets the video to the initial state
  function resetVideo(play_btn){
    play_btn.html('');
  }

  // Attach the event listeners
  $('.video-controls-w').each(function(){
    initializePlayer($(this));
  });
});